.rotating-words {
  display: inline-block;
  position:relative;
}
.rotating-words > span {
  display: none;
  opacity: 0;
}
body.sqs-edit-mode-active .rotating-words > span:first-child {
  display: inline-block;
  opacity: 1;
  transform: none;
}
.rotating-words > span.active.transition {
  opacity: 1;
   animation:rotateFadeOut var(--fade-duration) forwards;
}
.rotating-words > span.active {
  display: inline-block;
 animation:rotateFadeIn var(--fade-duration) forwards;
}
@keyframes rotateFadeIn {
  to {opacity: 1;}
}
@keyframes rotateFadeOut {
  to {opacity: 0;}
}
/* Slide In Bounce */
.sqs-block-content .rotating-words.slide {
  display: inline-grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr;
  justify-items: start;
  overflow: hidden;
}
.sqs-block-content center .rotating-words.slide[style*="block"] {
  justify-items: center;
}
.sqs-block-content .rotating-words.slide[style*="block"] {
  display: grid!important;
}
.sqs-block-content .rotating-words.slide > span {
  grid-column-start: 1;
  grid-row-start: 1;
  display: block;
  opacity: 0;
  transform: translateY(100%);
  animation: slide-out-bounce var(--fade-duration) forwards;
}
.sqs-block-content .rotating-words.slide > span.active {
  animation: slide-in-bounce var(--fade-duration) forwards;
  opacity: 1;
}
.rotating-words[data-loop-stop="true"] > span:last-child.transition {
  opacity: 1!important;
}
@keyframes slide-in-bounce {
0% {
opacity: 0;
transform: translateY(-100%);
}
60% {
opacity: 1;
transform: translateY(var(--bounce-in));
}
100% {
opacity: 1;
transform: translateY(0);
}
}
@keyframes slide-out-bounce {
0% {
opacity: 1;
transform: translateY(0);
}
60% {
opacity: 0;
transform: translateY(var(--bounce-out));
}
100% {
opacity: 0;
transform: translateY(100%);
}
}

/* Center Fixed Styling */
.center-fixed {
  display: flex;
  justify-content: center;
  text-align: center;
}
.center-fixed > * {
  text-align: center;
}
.center-fixed .rotating-words {
    display: inline-grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    width: max-content;
    text-align: left;
}
.center-fixed .rotating-words > span {
  grid-column-start: 1;
  grid-row-start: 1;
  display: block;
}

